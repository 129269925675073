@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import '../../../../libs/ui/src/index.css';

@import 'tailwindcss/utilities';

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}

@layer components {
  .bc-toast-container.Toastify__toast-container {
    width: calc(100vw - 1rem);
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .bc-toast-container .bc-toast.Toastify__toast {
    @apply p-0 rounded-2xl;
  }

  .bc-toast-container .bc-toast.Toastify__toast--success {
    @apply bg-green-400;
  }

  .bc-toast-container .bc-toast.Toastify__toast--error {
    @apply bg-red-400;
  }

  .bc-toast-container .bc-toast .Toastify__toast-body {
    @apply p-4 m-0 space-x-2;
  }

  .bc-toast-container .bc-toast .Toastify__toast-icon {
    @apply w-6 h-6 m-0;
  }

  .bc-toast-container .bc-toast .Toastify__toast-body > div:last-child {
    @apply text-base font-light font-primary;
  }

  .bc-mapbox-popup.mapboxgl-popup-anchor-bottom {
    /* pointer-events: none; */

    @apply drop-shadow-lg dark:drop-shadow-lg;

    & > .mapboxgl-popup-content {
      /* pointer-events: none; */
      @apply p-2 bg-gray-500 rounded-2xl;
    }

    & > .mapboxgl-popup-tip {
      @apply border-t-gray-500;
    }
  }
}
